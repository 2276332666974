import { createRef, lazy } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

// Mui
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// Settings
import versionObj from "./version.json";

// Styles
import "./scss/App.scss";

// Custom components
import NavigationScroll from "./components/layout/NavigationScroll";
import Loadable from "./components/general/Loadable";
import themes from "./themes";

// Utils
import settings from "./settings.json";

// Hooks
import { useFetchUser } from "./hooks/useFetchUser";

// Pages
const Home = Loadable(lazy(() => import("./components/pages/Home")));
const MainLayout = Loadable(lazy(() => import("./components/layout/MainLayout")));
const Login = Loadable(lazy(() => import("./components/pages/auth/Login")));
const ForgotPassword = Loadable(lazy(() => import("./components/pages/auth/ForgotPassword")));
const ChangePassword = Loadable(lazy(() => import("./components/pages/auth/ChangePassword")));

const PrivateRoute = ({ title = [], children }: any) => {
    const { currentUser } = useFetchUser();

    document.title = `${title} - ${settings.app.title}`;

    // Hack-ish code to return you to the page you were before refresh
    if (!currentUser) {
        if (!window.location.href.includes("/login")) {
            return <Login />;
        }
    }

    return children;
};

const PublicRoute = ({ title, children }: any) => {
    document.title = `${title} - ${settings.app.title}`;
    return children;
};

const App: React.FC<any> = () => {
    console.log(`%cVersion: ${versionObj && versionObj.version}`, "color: #bada55");

    const snackbarRef: any = createRef();

    return (
        <StyledEngineProvider injectFirst>
            <SnackbarProvider
                className="snackbar__container"
                ref={snackbarRef}
                maxSnack={3}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                autoHideDuration={2000}
            >
                <ThemeProvider theme={themes()}>
                    <CssBaseline />
                    <NavigationScroll>
                        <BrowserRouter>
                            <Routes>
                                <Route
                                    path="/redeem/:qrCode"
                                    element={
                                        <PrivateRoute title="Redeem" rolesAllowed={["superAdmin", "admin"]}>
                                            <Home />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/forgotPassword"
                                    element={
                                        <PublicRoute title="Mot de passe oublié">
                                            <ForgotPassword />
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/changePassword"
                                    element={
                                        <PublicRoute title="Changement de mot de passe">
                                            <ChangePassword />
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="*"
                                    element={
                                        <PrivateRoute title="Redeem" rolesAllowed={["superAdmin", "admin"]}>
                                            <Home />
                                        </PrivateRoute>
                                    }
                                />
                            </Routes>
                        </BrowserRouter>
                    </NavigationScroll>
                </ThemeProvider>
            </SnackbarProvider>
        </StyledEngineProvider>
    );
};

export default App;
